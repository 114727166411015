import React from 'react'
import NewPost from '../../../components/marketplace/NewAds'
import MasterLayout from '../../../layout/masterLayout'

export default function NewAds() {
    return (
        <MasterLayout>
            <NewPost />
        </MasterLayout>
    )
}
