import React from 'react'
import Registerbg from '../../components/auth/registerbg'
const registerbg = () => {
  return (
    <div>
         
         <Registerbg />

    </div>
  )
}

export default registerbg