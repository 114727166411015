import React, { useEffect, useState } from 'react'
import { DollarOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Cascader, Col, Divider, Form, Input, Modal, PageHeader, Row, Select, Space, Typography, Upload } from 'antd';
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import { useDispatch } from 'react-redux'
import { baseUrl } from '../../repositories/baseUrl';
import { openNotificationWithIcon, startloader, stoploader } from '../../Redux/Action/ActionFunction';
import axios from 'axios';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import CloseButton from 'react-bootstrap/CloseButton';
// import { DropzoneArea } from 'material-ui-dropzone';
// import Dropdown from 'react-bootstrap/Dropdown';
// import { AiFillCloseCircle } from "react-icons/ai";

export default function NewAds() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let Token = localStorage.getItem("farmerAdminToken");

    const [Ads, setAds] = useState([])

    const { Title } = Typography;
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [formLayout,] = useState('vertical');

    const [Tittle, setTittle] = useState('');
    const [Description, setDescription] = useState('');
    const [Category, setCategory] = useState([]);
    const [Condition, setCondition] = useState('');
    const [Locations, setLocations] = useState('');
    const [Brand, setBrand] = useState('');
    const [Price, setPrice] = useState('');

    const formItemLayout ={
        labelCol: {
            span: 10,
        },
        wrapperCol: {
            span: 24,
        }}
  
const buttonItemLayout =
formLayout === 'horizontal'
    ? {
        wrapperCol: {
            span: 14,
            offset: 4,
        },
    }
    : null;



    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });



    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
    ]);
    console.log("fileList", fileList);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );


    const options = [
        {
            value: 'Clothes',
            label: 'Clothes',
            children: [
                {
                    value: 'Fashion',
                    label: 'Fashion',
                    children: [
                        {
                            value: 'Shoes',
                            label: 'Shoes',
                        },
                    ],
                },
            ],
        },
        {
            value: 'Accessories',
            label: 'Accessories',
            children: [
                {
                    value: 'Electronic',
                    label: 'Electronic',
                    children: [
                        {
                            value: 'Computer',
                            label: 'Computer',
                        },
                    ],
                },
            ],
        },
    ];
    const onChange = (value) => {
        console.log(value);
        setCategory(value)
    };

    const handleInputChange = (e, func, type) => {
        if (type === "condition") {
            func(e);
        } else {
            func(e.target.value);
        }
    }

    const onFinish = (values) => {
        dispatch(startloader());
        const formData = new FormData();
        formData.append("title", Tittle);
        formData.append("description", Description);
        formData.append("category", Category);
        formData.append("condition", Condition);
        formData.append("price", Price);
        formData.append("brand", Brand);
        formData.append("location", Locations);
        fileList.map((e) => formData.append("file[]", e.originFileObj))
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/add/post`,
            data: formData,
            headers: {
                Authorization: `Bearer ${Token}`
            },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                console.log("Data", Data)
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
                navigate("/Pending");
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
                // console.log("Data", Data)
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if(Err.data.data){
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => openNotificationWithIcon("warning", "Opps", Data[e][0]));
                }else{
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        // history.push('/login')
                        navigate('/login')
                    }
                }
            }else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }






    useEffect(() => {
        getAds()
    }, [])



    const getAds = () => {
        dispatch(startloader());
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/all/pending/posts`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                console.log("Data.data", Data.data)
                setAds(Data.data);
                dispatch(stoploader())
            } else {
                dispatch(stoploader());
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });

    }

    const approveAds = (id) => {
        dispatch(startloader());
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/approve/user/post/${id}`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                dispatch(stoploader())
                openNotificationWithIcon("success", 'Success!', Data.message);
                setAds(Data.data.pendingpost);
            } else {
                dispatch(stoploader());
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });

    }
 
   



    return (
        <>
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                            <h3 className="content-header-title mb-0 d-inline-block">POST YOUR ADS</h3>
                            <div className="row breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper col-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item"><a href="#">Add New Ads</a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        {/* <div className="content-header-right col-md-6 col-12">
                            <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" onClick={()=>navigate("/New_Ads")} >Add New Ad</a>
                        </div> */}
                    </div>
                    <div className="content-body">
                        {/* Basic Tables start */}
                        {/* <div className="row">
                        <div className="col-12">
                            <div className="card-content collapse show">
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'Image', field: 'images',
                                            render: rowData => (
                                                <img src={baseUrl.Image_Url + rowData.images[0].imagepath} style={{ width: 50, borderRadius: '50%' }} />
                                            )
                                        },
                                        { title: 'Title', field: 'title' },
                                        { title: 'Category', field: 'category' },
                                        { title: 'Description', field: 'description' },
                                        { title: 'price', field: 'price' },
                                    ]}
                                    data={Ads}
                                    actions={[
                                        {
                                            icon: 'done',
                                            tooltip: 'Approve',
                                            onClick: (event, rowData) => {
                                                approveAds(rowData.id)
                                            }
                                        }
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}

                                    title=""
                                />
                            </div>
                        </div>
                    </div> */}
                        {/* Basic Tables end */}

                        {/* ant work */}
                        <Row justify='center' align='center' className='mt-1'>
                            {/* <Col sm={{ span: 24 }} md={{ span: 15 }}>
                                <Space direction='vertical' align='center' style={{ width: "100%" }}>
                                    <Title level={4}>POST YOUR AD</Title>
                                </Space>
                            </Col> */}
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Card
                                // title="SELECTED CATEGORY"
                                // extra={<a href="#">More</a>}
                                >
                                    <Title level={4}>Include Some Details</Title>
                                    <Form
                                        {...formItemLayout}
                                        layout={formLayout}
                                        form={form}
                                        name="control-hooks"
                                        onFinish={onFinish}
                                        initialValues={{
                                            layout: formLayout,
                                        }}
                                    >
                                        <Form.Item label="Ad title">
                                            <Input placeholder="Iphone 14 Pro" onChange={(e) => handleInputChange(e, setTittle)} />
                                        </Form.Item>
                                        <Form.Item label="Description">
                                            <TextArea rows={4} onChange={(e) => handleInputChange(e, setDescription)} />
                                        </Form.Item>

                                        <Form.Item label="Category">
                                            <Cascader options={options} onChange={onChange} placeholder="Please select" />
                                        </Form.Item>

                                        <Form.Item label="Condition">
                                            <Select onChange={(e) => handleInputChange(e, setCondition, "condition")}>
                                                <Select.Option value="New">New</Select.Option>
                                                <Select.Option value="Open Box">Open Box</Select.Option>
                                                <Select.Option value="Used">Used</Select.Option>
                                                <Select.Option value="Refurbished">Refurbished</Select.Option>
                                                <Select.Option value="For Parts or Not Working">For Parts or Not Working</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Divider />
                                        <Form.Item label="Location">
                                            <Select onChange={(e) => setLocations(e)}>
                                                <Select.Option value="Karahi">karachi</Select.Option>
                                                <Select.Option value="Lahore">Lahore</Select.Option>
                                                <Select.Option value="Islamabad">Islamabad</Select.Option>
                                                <Select.Option value="Quetta">Quetta</Select.Option>
                                                <Select.Option value="Peshawar">Peshawar</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Brand">
                                            <Select onChange={(e) => setBrand(e)}>
                                                <Select.Option value="Toyota">Toyota</Select.Option>
                                                <Select.Option value="BMW">BMW</Select.Option>
                                                <Select.Option value="Gucci">Gucci</Select.Option>
                                                <Select.Option value="Mazda">Mazda</Select.Option>
                                                <Select.Option value="Local">Local</Select.Option>
                                            </Select>
                                        </Form.Item>
                                        <Divider />
                                        <Title level={4}>SET A PRICE</Title>
                                        <Form.Item label="Price">
                                            <Input prefix={<DollarOutlined />} onChange={(e) => handleInputChange(e, setPrice)} />
                                        </Form.Item>
                                        <Divider />
                                        <Title level={4}>UPLOAD UP TO 10 PHOTOS</Title>
                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange}
                                            accept={"image/jpeg,image/png"}
                                        >
                                            {fileList.length >= 10 ? null : uploadButton}
                                        </Upload>
                                        <Divider />
                                        <Form.Item {...buttonItemLayout}>
                                            <Button type="primary" htmlType="submit" size='large' style={{ borderColor:"#FF394F",borderRadius:"1.5rem" , backgroundColor: "#FF394F" }}>Post now</Button>
                                        </Form.Item>
                                    </Form>
                                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                        <img
                                            alt="example"
                                            style={{
                                                width: '100%',
                                            }}
                                            src={previewImage}
                                        />
                                    </Modal>
                                </Card>
                                <br />
                            </Col>
                        </Row>


                        {/* ant work end */}
                    </div>
                </div>
            </div>


        </>




    )
}
