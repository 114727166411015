import React from 'react'
import Pending from '../../../components/marketplace/Pending'
import MasterLayout from '../../../layout/masterLayout'

export default function PendingAds() {
    return (
        <MasterLayout>
            <Pending />
        </MasterLayout>
    )
}
