import React from 'react'
import Rejected from '../../../components/marketplace/Rejected'
import MasterLayout from '../../../layout/masterLayout'

export default function RejectedAds() {
    return (
        <MasterLayout>
            <Rejected />
        </MasterLayout>
    )
}
