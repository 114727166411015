import React from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'

import Crypto from '../../components/dashboard/dashboardcrypto'

const Dashboardcrypto = () => {
    return (
        <>
        <Header />
           
           <Crypto />
            
            <Footer />

        </>
    )
}

export default Dashboardcrypto