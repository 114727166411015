import React from 'react'
import Loginbg from '../../components/auth/loginbg'

const loginbg = () => {
  return (
    <div>
      <Loginbg />
    </div>
  )
}

export default loginbg