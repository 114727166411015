import React from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'

import Usercontact from '../../components/user/userlist'
const userlist = () => {
  return (
    <div>
        <Header />
     <Usercontact />
        <Footer />

    </div>
  )
}

export default userlist