import React from 'react'
import Ecommerce_Card from '../../components/card/cardecommerce'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'

export default function EcommerceCard() {
  return (<>
  <Header />
  <Ecommerce_Card/>
  <Footer/>
  </>
  )
}
