import React from 'react'
import LocationCom from '../../../components/marketplace/Location'
import MasterLayout from '../../../layout/masterLayout'

export default function Category() {
    return (
        <MasterLayout>
        <LocationCom />
        </MasterLayout>
    )
}
