import React from 'react'

import SimpleLogin from '../../components/auth/simplelogin'

const simplelogin = () => {
  return (
    <>
     
     <SimpleLogin />

    </>
  )
}

export default simplelogin