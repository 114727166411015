import React from 'react'

import Usercard from '../../components/user/usercard'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'

const usercard = () => {
  return (
    <div>

        <Header />

    <Usercard />
        <Footer />

    </div>
  )
}

export default usercard