import React from 'react'
import CategoryCom from '../../../components/marketplace/Category'
import ThreeDatad from '../../../components/marketplace/ThreeData'
import MasterLayout from '../../../layout/masterLayout'

export default function Category() {
    return (
        <MasterLayout>
        <CategoryCom />
        {/* <ThreeDatad /> */}
        </MasterLayout>
    )
}
