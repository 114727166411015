import React from 'react'

import SimpleRegister from '../../components/auth/simpleregister'

const simpleregister = () => {
  return (
    <>
     
     <SimpleRegister />

    </>
  )
}

export default simpleregister