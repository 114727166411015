import React from 'react'
import BrandCom from '../../../components/marketplace/Brand'
import MasterLayout from '../../../layout/masterLayout'

export default function Brand() {
    return (
        <MasterLayout>
        <BrandCom />
        </MasterLayout>
    )
}
