import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MaterialTable from 'material-table'
import { useDispatch } from 'react-redux'
import { baseUrl } from '../../repositories/baseUrl';
import { openNotificationWithIcon, startloader, stoploader } from '../../Redux/Action/ActionFunction';
import axios from 'axios';
import { FaBan,FaWindowClose } from "react-icons/fa";

export default function RejectApprove() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let Token = localStorage.getItem("farmerAdminToken");

    const [Ads, setAds] = useState([])

    useEffect(() => {
        getAds()
    }, [])
    
    const getAds = () => {
        dispatch(startloader());
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/all/approved/post`,
            // url: `${baseUrl.Base_Url}/all/pending/posts`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                console.log("Data.data", Data.data)
                setAds(Data.data);
                dispatch(stoploader())
                // openNotificationWithIcon("success", 'Good bye!', Data.message);
            } else {
                dispatch(stoploader());
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });

    }

    const rejectAds = (id) => {
        dispatch(startloader());
        const formData = new FormData();
        formData.append('id',id);
        axios({
            method: 'post',
            // reject/post
            // url: `${baseUrl.Base_Url}/approve/user/post/${id}`,
            url: `${baseUrl.Base_Url}/reject/post`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                dispatch(stoploader())
                openNotificationWithIcon("success", 'Success!', Data.message);
                // setAds(Data.data.pendingpost);
                setAds(Data.data.non_rejected);
            } else {
                dispatch(stoploader());
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });

    }
    const unApproveAds = (id) => {
        dispatch(startloader());
        const formData = new FormData();
        formData.append('id',id);
        axios({
            method: 'post',
            
            url: `${baseUrl.Base_Url}/un-approved/post`,
            // url: `${baseUrl.Base_Url}/approve/user/post/${id}`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data

            
            if (Data.success) {
                dispatch(stoploader())
                openNotificationWithIcon("success", 'Success!', Data.message);
                console.log("unapproved post==>",Data.data.approved_posts)
                // setAds(Data.data.pendingpost);
                setAds(Data.data.approved_posts);
                
            } else {
                dispatch(stoploader());
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });

    }

    

    return (
        <div className="app-content content">
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                        <h3 className="content-header-title mb-0 d-inline-block">All Ads</h3>
                        <div className="row breadcrumbs-top d-inline-block">
                            <div className="breadcrumb-wrapper col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item"><a href="#">Approve Ads</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content-header-right col-md-6 col-12">
                        <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" href="#" >Add New Ad</a>
                    </div> */}
                </div>
                <div className="content-body">
                    {/* Basic Tables start */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card-content collapse show">
                                <MaterialTable
                                    columns={[
                                        {
                                            title: 'Image', field: 'images',
                                            render: rowData => (
                                                <img src={baseUrl.Image_Url + rowData.images[0].imagepath} style={{ width: 50, borderRadius: '50%' }} />
                                            )
                                        },
                                        { title: 'Title', field: 'title' },
                                        { title: 'Category', field: 'category' },
                                        { title: 'Description', field: 'description' },
                                        { title: 'price', field: 'price' },
                                        // { title: 'description', field: 'birthYear', type: 'numeric' },
                                        // { title: 'Doğum Yeri', field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' } }
                                    ]}
                                    data={Ads}
                                    actions={[
                                        {
                                            icon: ()=><FaBan/>,
                                            tooltip: 'Reject This Ad',
                                            onClick: (event, rowData) => {
                                                rejectAds(rowData.id)
                                            }
                                        },
                                        {
                                            icon: ()=><FaWindowClose/>,
                                            tooltip: 'Un approve This Ad',
                                            onClick: (event, rowData) => {
                                                console.log("id of unproved",rowData.id)
                                                unApproveAds(rowData.id)
                                            }
                                        }
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1
                                    }}

                                    title=""
                                />
                            </div>
                            
                        </div>
                    </div>
                    {/* Basic Tables end */}
                </div>
            </div>
        </div>
    )
}
