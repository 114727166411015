import React from 'react'
import AdminDashboard from '../../components/dashboard/dashboard'
import MasterLayout from '../../layout/masterLayout'

const Dashboard = () => {
    return (
        <MasterLayout>
            <AdminDashboard />
        </MasterLayout>
    )
}

export default Dashboard