import React from 'react'
import SubCategoryCom from '../../../components/marketplace/SubCategory'
import ThreeDatad from '../../../components/marketplace/ThreeData'
import MasterLayout from '../../../layout/masterLayout'

export default function SubCategory() {
    return (
        <MasterLayout>
        <SubCategoryCom />
        {/* <ThreeDatad /> */}
        </MasterLayout>
    )
}
