import React from 'react'
import RejectApprove from '../../../../components/marketplace/RejectApprove'
import MasterLayout from '../../../../layout/masterLayout'

export default function RejectedApprove() {
    return (
        <MasterLayout>
            <RejectApprove />
        </MasterLayout>
    )
}
