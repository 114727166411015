import React from 'react';
import { Loader } from 'react-overlay-loader';
import { useSelector } from 'react-redux';
import Footer from './Footer';
import Header from './Header';

const MasterLayout = ({ children }) => {
    const loader = useSelector(state => state.utils.loader);
    
    return (
        <>
            {loader ? <Loader fullPage loading /> : null}
            <Header />
            {children}
            <Footer />
        </>
    )
};
export default MasterLayout;