import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import MaterialTable from 'material-table'
import { useDispatch } from 'react-redux'
import { baseUrl } from '../../repositories/baseUrl';
import { openNotificationWithIcon, startloader, stoploader } from '../../Redux/Action/ActionFunction';
import { Button, Card, Cascader, Col, Divider, Form, Input, Modal, PageHeader, Row, Select, Space, Typography, Upload } from 'antd';
import axios from 'axios';
import Swal from 'sweetalert2';
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import CloseButton from 'react-bootstrap/CloseButton';
// import { DropzoneArea } from 'material-ui-dropzone';
// import Dropdown from 'react-bootstrap/Dropdown';
// import { AiFillCloseCircle } from "react-icons/ai";

export default function SubCategory() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
                      
   const Parent_Id =  useParams()

   console.log("Parent_Id",Parent_Id)

    let Token = localStorage.getItem("farmerAdminToken");



    const [Name,setName]=useState('')

    const { Title } = Typography;
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [formLayout,] = useState('vertical');

    const [Ads, setAds] = useState([])
    // add modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    // edited modal
    const [isModalOpen2, setIsModalOpen2] = useState(false);

    // getting update id
    const [TabelId, SetTabelId] = useState('');


    const [CategoryName,setCategoryName]=useState('')
    


    const formItemLayout ={
        labelCol: {
            span: 10,
        },
        wrapperCol: {
            span: 24,
        }}


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        form.submit()
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // edited modal
    const showModal2 = () => {
        setIsModalOpen2(true);
    };
    const handleOk2 = () => {
        form.submit()
        setIsModalOpen2(false);
    };
    const handleCancel2 = () => {
        setIsModalOpen2(false);
    };



    const handleInputChange = (e, func, type) => {
        console.log("input value==>",func)
       
            func(e.target.value);
    
    }

    // Add category
    const onFinish = () => {

        dispatch(startloader());
        const formData = new FormData();
        formData.append("name", Name);
        formData.append("parent_id", Parent_Id.id);
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/admin/post/add/category`,
            data: formData,
            headers: {
                Authorization: `Bearer ${Token}`
            },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                console.log("Data==>", Data)
                // console.log("Data.data.subcategory ==>", Data.data.Parent_Id)
                console.log("Data.data.subcategory add time ==>",Data.data)
                console.log("Data.data add time push ==>",Data.data.parentrecursive[0].subcategory_recursive)
                setAds(Data.data.parentrecursive[0].subcategory_recursive)
                setAds(Data.data.name)
                // getAds()
                setName('')
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
                // navigate("/Pending");
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
                // console.log("Data", Data)
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if(Err.data.data){
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => openNotificationWithIcon("warning", "Opps", Data[e][0]));
                }else{
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        // history.push('/login')
                        navigate('/login')
                    }
                }
            }else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }

    // update category

    const Edited = (e) => {
        console.log("i am running")
        console.log("value of rowdata id is ==>", e)
        SetTabelId(e)
        showModal2()
        // handleShow2()
    }

    const onFinish2 = () => {
        // console.log("rowdata==>",data.id)

        dispatch(startloader());
        const formData = new FormData();
        formData.append("id", TabelId);
        formData.append("name", Name);
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/admin/post/update/category`,
            data: formData,
            headers: {
                Authorization: `Bearer ${Token}`
            },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                console.log("Data==>", Data)
                console.log("Data.data==>", Data.data)
                setAds(Data.data)
                // setName('')
                openNotificationWithIcon("success", 'Congratulations!', Data.message);
                // navigate("/Pending");
            } else {
                openNotificationWithIcon("warning", 'Opps!', Data.message);
                // console.log("Data", Data)
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if(Err.data.data){
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => openNotificationWithIcon("warning", "Opps", Data[e][0]));
                }else{
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        // dispatch(authLogout());
                        // history.push('/login')
                        navigate('/login')
                    }
                }
            }else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });
    }



    useEffect(() => {
        getAds()
    }, [Parent_Id.id])



    const getAds = () => {
        dispatch(startloader());
        axios({
            method: 'get',
            url: `${baseUrl.Base_Url}/get/specific/child/category/${Parent_Id.id}`,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                console.log("Get Data.data==>", Data.data[0].name)
                setCategoryName(Data.data[0].name)
                console.log("Get Data.data==>", Data.data[0].subcategory_recursive)
                setAds(Data.data[0].subcategory_recursive);
                dispatch(stoploader())
            } else {
                dispatch(stoploader());
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });

    }

    const DeleteService = (e) => {

        console.log("value of delete id ==>", e)
        // setId(e)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#29BF12',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {


                // SetTabelId(e)
                ConfirmDelete(e)

            }
        })

    }


    const ConfirmDelete = (id) => {
        console.log("apna id==>",id)
        dispatch(startloader());
        const formData = new FormData();
        // formData.append("id", TabelId);
        formData.append("id",id);
        axios({
            method: 'post',
            url: `${baseUrl.Base_Url}/admin/post/del/category`,
            data: formData,
            headers: { Authorization: `Bearer ${Token}` },
        }).then(function (response) {
            dispatch(stoploader())
            const Data = response.data
            if (Data.success) {
                dispatch(stoploader())
                openNotificationWithIcon("success", 'Success!', Data.message);
                setAds(Data.data);
            } else {
                dispatch(stoploader());
                openNotificationWithIcon("warning", "Opps!", Data.message);
            }
        }).catch((error) => {
            const Err = error.response
            dispatch(stoploader())
            if (Err !== undefined) {
                if (Err.data.data) {
                    const Data = Err.data.data;
                    const ErrList = Object.keys(Data);
                    ErrList.map((e) => {
                        openNotificationWithIcon("warning", "Opps", Data[e][0]);
                    });
                } else {
                    openNotificationWithIcon("error", "Opps", Err.data.message);
                    if (Err.data.message === "Unauthenticated.") {
                        navigate('/login')
                    }
                }
            } else {
                openNotificationWithIcon("error", "Opps", error.message);
            }
        });

    }


  const  AddSubCategory = (data) =>{

    navigate(`/SubCategory/${data}`)

    console.log("ahmed==>",data)

  }



    return (
        <>
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                            <h3 className="content-header-title mb-0 d-inline-block">Sub Category</h3>
                            <div className="row breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper col-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link>
                                        </li>
                                        {/* <li className="breadcrumb-item"><a onClick={()=>navigate('/Category')}>Category</a>
                                        </li> */}
                                        <li className="breadcrumb-item"><a href="#">Add Sub Category</a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="content-header-right col-md-6 col-12">
                            <a className="btn btn-md btn-danger box-shadow-2 round btn-min-width pull-right" onClick={showModal} >Add Sub Category</a>
                        </div>
                    </div>
                    <div className="content-body">
                        {/* Basic Tables start */}
                        <div className="row">
                            <div className="col-12">
                                <div className="card-content collapse show">
                                    <MaterialTable
                                        columns={[

                                            { title: 'Sub Category', field: 'name' },
                                            // {
                                            //     title: "Add New Sub Category", field: "images", render: rowData =>
        
                                            //         <Button className='btn btn-md btn-danger box-shadow-2  round px-2' onClick={() => AddSubCategory(rowData.id)}  >Add Sub Category </Button>
        
                                            // },

                                        ]}
                                        data={Ads}
                                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                        actions={[
                                              {
                                                icon: 'add',
                                                tooltip: 'View Sub Category',
                                                onClick: (event, rowData) => {
                                                    console.log("rowData==>",rowData)
                                                    AddSubCategory(rowData.id)
                                                //    setName(rowData.name) 
                                                //    Edited(rowData.id)
                                                }
                                            },
                                            // {
                                            //     icon: 'edit',
                                            //     tooltip: 'Edit',
                                            //     onClick: (event, rowData) => {
                                            //         console.log("rowData==>",rowData)
                                            //        setName(rowData.name) 
                                            //        Edited(rowData.id)
                                            //     }
                                            // },
                                            // {
                                            //     icon: 'delete',
                                            //     tooltip: 'Delete',
                                            //     onClick: (event, rowData) => {
                                            //         console.log("rowData==>",rowData)
                                            //         DeleteService(rowData.id)
                                            //     }
                                            // }
                                        ]}
                                        options={{
                                            actionsColumnIndex: -1
                                        }}

                                        title={CategoryName}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Basic Tables end */}
                    </div>
                </div>
            </div>
             {/* add category modal  start*/}
            <Modal title="Add Sub Category"
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Submit
                    </Button>,
                ]}

                open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    initialValues={{
                        layout: formLayout,
                    }}
                >
                    <Form.Item label="Sub Category Name">
                        <Input placeholder="Sub Category Name"  onChange={(e) => handleInputChange(e, setName)} />
                    </Form.Item>

                </Form>
            </Modal>
            {/* add category model end */}

            {/* Edit category model */}
                
            <Modal title="Edit Category"
                footer={[
                    <Button key="back" onClick={handleCancel2}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk2}>
                        Submit
                    </Button>,
                ]}

                open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
                <Form
                    {...formItemLayout}
                    layout={formLayout}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish2}
                    initialValues={{
                        layout: formLayout,
                    }}
                >
                    <Form.Item label="Category Name">
                        <Input placeholder="Category Name" value={Name} onChange={(e) => handleInputChange(e, setName)} />
                    </Form.Item>

                </Form>
            </Modal>

            {/* Edit category model */}
        </>




    )
}
